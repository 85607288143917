.video__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  video {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
