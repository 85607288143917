.error_wrapper {
  width: 100vw;
  height: 100vh;
  background-color: #f7f5f0;
  display: flex;
  align-items: center;
  justify-content: center;
  .error {
    text-align: center;
    p {
      margin-bottom: 10px;
    }
    button {
      margin-top: 10px;
    }
  }
}
