@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';
.wrapper_agence {
  width: 100vw;
  height: 100vh;
  position: relative;
  .loadingForm {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    z-index: 2;
  }
}

.blockRowSpaceBetween {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.blockWithBorder {
  border: solid;
  border-width: 0.1rem;
  border-color: blue;
  border-radius: 3px;
  border-style: dotted;
  margin-top: 1rem;
}
.blockRowSpaceBetweenWithMargin {
  margin-top: 1.5rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
  margin-right: 1rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.titleBlock {
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: -19px;
  margin-left: 0px;
  margin-right: 9px;
  @include XXSnavyleft;
}
