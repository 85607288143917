.cxp {
  position: relative;
  width: 100vw;
  height: 100vh;
  .cxp_container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 5;
    left: 0;
    background-color: #6ba5b4;
    background-image: url(../../Assets/cxps/cxpbg.svg);
    background-repeat: no-repeat;
    background-position: -15px center;
  }
  .title-cxp {
    color: white;
    position: relative;
    top: 5vh;
    left: 1.66vw;
    font-size: 2em;
    text-transform: lowercase;
  }
  .text {
    color: #e8f1fb;
  }
  .select {
    margin-bottom: 22px;
    color: #03479b;
  }

  .icon-item-delete {
    background-color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin: 0 0 0 8px;
    padding: 7px;
    background-color: rgba(33, 117, 217, 0.3);
    cursor: pointer;
  }
  .item-text {
    color: #2175d9;
  }
  .item-delete {
    display: flex;
    justify-content: space-between;
    padding: 5px 5px 5px 15px;
    background-color: #e8f1fb;
    border-radius: 30px;
    margin: 0 8px 0 0;
  }

  .item-delete-without-close {
    display: flex;
    justify-content: space-between;
    padding: 5px 15px 5px 15px;
    background-color: #e8f1fb;
    border-radius: 30px;
    margin: 0 8px 0 0;
  }
  .item {
    margin-bottom: 2.96vh;
  }
  .tags {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    height: 25vh;
    overflow-y: auto;
  }
  .container {
    width: 55vw;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -56%);
    height: 400px;
  }
  .bloc {
    width: 100%;
  }
  .loaderIcon {
    margin-top: 45vh;
    width: 35px;
    height: 35px;
  }
  .loaderCxp {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100vw;
    height: 100vh;
  }
  .button__left {
    list-style-type: none;
    list-style-type: none;
    position: relative;
    top: 2.96vh;
    left: 1.66vw;
    z-index: 10;
    display: flex;
    .back {
      display: inline-block;
      margin-right: 5px;
      button {
        background: transparent;
        // width: 56px;
        // height: 56px;
        // background-color: #2175d9;
        border: 0;
        cursor: pointer;
        img {
          width: 24px;
          height: 24px;
          display: inline-block;
        }
      }
    }
    .back-save {
      color: #e8f1fb;
      display: inline-block;
      margin-right: 5px;
      button {
        color: white;
        font-weight: bold;
        width: 112px;
        height: 56px;
        background-color: #2175d9;
        border: 0;
        cursor: pointer;
        img {
          display: inline-block;
        }
      }
    }
  }
}
