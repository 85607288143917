.navbar {
  // padding: 3.33vh 3vh;
  padding: 2vh 3vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 11.5vh;
  .logo {
    width: calc(150px + 0.5vw);
    height: auto;
    display: flex;
    align-items: center;
    span {
      color: #afafaf;
      display: inline-block;
      padding-left: 10px;
      font-size: 12px;
    }
  }
  .menu {
    display: block;
    height: fit-content;
    & > li {
      display: inline-block;
      & > button {
        background-color: transparent;
        border: 0;
        display: inline-flex;
        position: relative;
        width: calc(40px + 0.5vw);
        height: calc(40px + 0.5vw);
        border-radius: 50%;
        line-height: calc(40px + 0.5vw);
        justify-content: center;
        align-items: center;
        .notif {
          position: absolute;
          top: 9px;
          right: 6px;
          width: 8px;
          height: 8px;
          background-color: #f24747;
          border-radius: 50%;
        }
        & > img {
          width: 51.6%;
        }
        &:hover,
        &.active {
          opacity: 1;
          background-color: rgba(255, 255, 255, 0.4);
        }
        &.inactive {
          opacity: 0.8;
          background-color: rgba(255, 255, 255, 0);
        }
      }
    }

    .sub-menu {
      position: absolute;
      top: 60px;
      right: 0;
      min-width: 290px;
      z-index: 4;
      background-color: #f9fafb;
      border-radius: 10px;
      list-style: none;
      padding: 15px 15px 15px 15px;

      .user-infos-menu {
        display: flex;
        align-items: center;
        flex-direction: row;
        border-bottom: 1px solid #cacaca;
        padding-bottom: 15px;
        .icon-name {
          padding-top: 6px;
          background-color: #2175d9;
          border-radius: 50%;
          width: 60px;
          height: 60px;
          text-align: center;
          color: white;
          font-size: 20px;
        }
        .block__user__infos {
          margin-left: 10px;
          /* display: flex; */
          height: auto;
          overflow: visible;
          .user-name {
            height: auto;
            font-size: 18px;
            color: rgb(73, 73, 73);
            font-weight: 500;
            line-height: initial;
            margin-bottom: 0;
            text-align: left;
          }
          .user-email {
            height: auto;
            font-size: 12px;
            color: #494949;
            line-height: initial;
            text-align: left;
          }
        }
      }

      &::before {
        content: '';
        position: absolute;
        top: -7px;
        right: 15px;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid #f9fafb;
      }
      li {
        position: relative;
        height: auto;
        line-height: 0;
        border-bottom: 1px solid #cacaca;
        padding: 10px 0;
        &:last-child {
          margin-top: 5px;
          height: auto;
          border-bottom: 0;
          margin-top: 0;
          padding-bottom: 0;
        }
        &:first-child button {
          padding-top: 0;
        }
        &:nth-child(1) button,
        &:nth-child(2) button {
          display: block;
          font-size: 1.125em;
          color: #2175d9;
          padding: 5px 0;
          height: 40px;
          display: flex;
          align-items: center;
          width: 100%;
          text-align: left;
          justify-content: left;
          background-color: transparent;
          border: 0;
          cursor: pointer;
          img {
            display: inline-block !important;
            margin-right: 5px;
            height: 16px;
            width: 16px;
          }
        }
        &:nth-child(3) button {
          width: 100%;
        }
      }
    }
    .sub-menu-apps {
      position: absolute;
      top: 60px;
      right: 0;
      min-width: 290px;
      z-index: 4;
      background-color: #f9fafb;
      border-radius: 10px;
      padding: 15px;
      display: flex;
      flex-wrap: wrap;
      line-height: 12px;
      .item-apps {
        width: 33.33%;
        text-align: center;
        position: relative;
        line-height: inherit;
        margin-bottom: 12px;
        padding: 5px;
        border-radius: 5px;
        &:hover {
          background-color: #edf0f5;
          span {
            color: #0f1941 !important;
          }
        }
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          margin-bottom: 0;
        }
        img {
          display: inline-block;
        }
        .img-apps {
          text-align: center;
        }
        span {
          font-size: 12px;
          color: #8a8a8a;
        }
        .link-apps {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
      }

      &::before {
        content: '';
        position: absolute;
        top: -7px;
        right: 15px;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid #f9fafb;
      }
    }
  }
}
.alert__sync {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 25px;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 8px;
  color: #0f1941;
  display: flex;
  flex-flow: row wrap;
  height: auto;
  padding: 12px 20px 18px;
  visibility: visible;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 450px;
  transform: translate(-50%, -50%);
  z-index: 4;

  .alert__message {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 25px;
    margin-bottom: 0;
  }
  .alert__cta {
    margin: 10px 0 0 auto;
    padding-left: 10px;
    text-align: right;
    width: 100%;
    .alert__cta__link {
      -webkit-transition: color 0.2s;
      transition: color 0.2s;
      margin-left: 10px;
      background: transparent;
      border: 0;
      color: #878ca0;
      font-size: 17px;
      cursor: pointer;
      &:nth-child(2),
      &:hover {
        color: #2175d9;
      }
    }
  }
}
