.toast {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 25px;
  background: #0f1941;
  border: 1px solid #fff;
  border-radius: 8px;
  color: #f7f5f0;
  display: flex;
  flex-flow: row wrap;
  height: auto;
  padding: 12px 20px 18px;
  visibility: visible;
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 450px;
  z-index: 99;
  .toast__message {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 25px;
    margin-bottom: 0;
  }
  .toast__cta {
    margin: 10px 0 0 auto;
    padding-left: 10px;
    text-align: right;
    width: 100%;
    .toast__cta__link {
      -webkit-transition: color 0.2s;
      transition: color 0.2s;
      margin-left: 10px;
      background: transparent;
      border: 0;
      color: #878ca0;
      cursor: pointer;
      &:nth-child(2),
      &:hover {
        color: #2175d9;
      }
    }
  }
}
