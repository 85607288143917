.title {
  display: block;
  // width: 14.89vw;
  width: 18.89vw;
  // margin-right: 3.54vw;
  font-size: 1.875em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: -1px;
  color: #0f1941;
}
.items {
  width: 81.77vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 3vh 3vw;
  overflow-y: auto;
  &.items-scroll {
    padding-right: 5px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #c7dcf5;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #c7dcf5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #2175d9;
  }
  .item {
    height: auto;
    a {
      height: 7.5vh;
      width: 100%;
      max-width: 100% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1.8vh;
      border-radius: 4px;
      text-decoration: none;
      font-size: 1em;
      img {
        margin-right: 1.04vw;
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .title {
    margin-bottom: 15px;
  }
}
