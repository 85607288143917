// prettier-ignore
@import-normalize "opinionated.css";
* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  font-weight: normal;
}

body {
  margin: 0;
  font-family: 'Graphik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
html,
body,
#root,
.App {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #f7f5f0;
}
.container {
  width: calc(100% - 3vh);
  margin: 0 auto;
  min-width: 960px;
  min-height: 540px;
}
html {
  font-size: 16px !important;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #c7dcf5;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #2175d9;
}
@media screen and (max-width: 1080px) {
  html {
    font-size: 14px !important;
  }
}
@media screen and (min-width: 1200px) {
  html {
    font-size: 16.5px !important;
  }
}

@media screen and (min-width: 1300px) {
  html {
    font-size: 17px !important;
  }
}
