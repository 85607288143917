.intro {
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: rgb(247, 245, 240);
  justify-content: center;
  align-items: center;
  .card {
    box-shadow: 0 5px 10px 0 rgba(0, 2.5, 5px, 0.5);
    background-color: #fff;
    width: 340px;
    height: 540px;
    padding: 31px 24px 24px 25px;
    border-radius: 8px;
    background-color: rgb(15, 25, 65);
    button {
      padding: 16px;
      display: block;
      width: 100%;
      border-radius: 4px;
      background-color: #fff;
      color: rgb(33, 117, 217);
      font-size: 15px;
      border: 0;
      cursor: pointer;
    }
    a {
      width: 100%;
    }
    .logo-puzzle {
      margin-bottom: 36px;
      display: block;
    }
    .text-1 {
      font-size: 26px;
      line-height: 1.15;
      letter-spacing: -1px;
      color: #fff;
      font-weight: normal;
      margin-bottom: 8px;
    }
    .text-2 {
      font-size: 16px;
      line-height: 1.25;
      font-weight: normal;
      letter-spacing: normal;
      color: #fff;
      margin-bottom: 49px;
    }
    .logo-randstad {
      display: block;
      margin-bottom: 91px;
    }
  }
}
