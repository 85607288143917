@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.my-popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 50%;
  padding: 5px;
}
.my-popup-arrow {
  color: rgb(255, 255, 255);
}
[role='tooltip'].my-popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.my-popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].my-popup-overlay {
  background: transparent;
}

.title {
  width: 35rem;
  height: 2rem;
  margin: 1rem 0 1.5rem 1.5rem;
  font-family: Graphik;
  font-size: 1.563rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: -0.5px;
  color: var(--main-navy-full);
}

.text {
  font-family: Graphik;
  color: $main_navy_full;
  font-size: 0.9rem;
  line-height: 2rem;
  //overflow: scroll;
}
