.doc__sync {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 25px;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 8px;
  color: #0f1941;
  display: flex;
  flex-direction: column;
  height: auto;
  visibility: visible;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 608px;
  transform: translate(-50%, -50%);
  z-index: 4;
  .doc__sync__inner {
    padding: 20px 15px 20px 20px;
  }
  .doc__header {
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    align-items: center;
    position: relative;
    .icon {
      margin-left: -5px;
    }
    .close {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 37px;
      right: 20px;
      cursor: pointer;
    }
  }
  .doc__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    img {
      cursor: pointer;
    }
  }
  .doc__top_icon {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 1vh;
    .doc_icon_close {
      height: 15px;
    }
    .doc_txt_back {
      padding-left: 10px;
      font-size: 12px;
      color: #2175d9;
    }
  }
  .doc__title__name {
    font-size: 25px;
    display: block;
  }
  .doc__block {
    max-height: 70vh;
    overflow-y: auto;
  }
  .inputWithIcon input[type='text'] {
    padding-left: 60px;
  }
  .inputWithIcon {
    width: calc(100% - 5px);
    // margin-top: 3vh;
    flex-flow: row wrap;
    height: 72px;
    .inputIcon {
      position: relative;
      top: -41px;
      width: 20px;
      fill: #2175d9;
      left: 20px;
      color: #aaa;
      transition: 0.3s;
    }
    .inputSearch {
      min-height: 54px;
    }
  }
  .documentsFiltredNb {
    font-size: 13px;
    color: #aaa;
  }
  li {
    width: calc(100% - 5px);
    display: flex;
    flex-direction: row;
    text-align: center;
    cursor: pointer;
    padding: 10px 0;
    padding-left: 0.5vw;
    align-items: center;
    border-top: 1px solid rgb(215, 215, 215);

    .doc__icon_zone {
      margin-right: 1.3vw;
      width: 40px;
      height: 40px;
      background-color: #2175d9;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-content: center;
      .doc__icon {
        fill: white;
        width: 16px;
      }
    }
    .doc__name {
      text-align: left;
    }
  }
}
