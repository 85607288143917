@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.icon {
  display: flex;
  align-items: center;
}

.leftIcon {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}

.input_file_wrap {
  width: 100%;
  height: 100px;
  background-color: transparent;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  background-color: #fff;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  .input_file_label {
    color: #717171;
    font-weight: 400;
    font-size: 18px;
    & > span {
      font-weight: normal;
      color: #2175d9;
    }
  }
  & > input {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }
}

.previewImage_wrap {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #d7d7d7;
  background-color: #fff;
  .previewImage {
    width: 30%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .previewImageDetail {
    padding-left: 60px;
    button {
      color: #2175d9;
      background: transparent;
      border: transparent;
      font-size: 17px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
    }
  }
}

.colFileName {
  font-family: Graphik;
  font-size: 1rem;
  color: $main-blue-full;
}

.colFileSize {
  font-family: Graphik;
  font-size: 0.75rem;
  color: $contrast_grey_strong;
}

.colDelete {
  width: 24px;
  text-align: end;
}

.colIcon {
  width: 24px;
}

.divider {
  border-top: 1px solid rgb(187, 187, 187);
  border-radius: 0px;
}

.tableContainer {
  font-family: Graphik;
  border-collapse: collapse;
  width: 100%;
}

table tbody tr {
  border-bottom: 1px solid $contrast-grey-medium;
  height: 100%;
}

table thead tr {
  border-bottom: 1px solid $contrast-grey-medium;
}

table tbody tr:hover0 {
  outline: 2px solid $main-blue-full;
  outline-offset: -1px;
}

.buttonClassName {
  color: red;
  background-color: green !important;
  border-bottom: 4px solid $contrast-grey-medium;
}
