.preview {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  .content {
    width: 100%;
    height: calc(100vh - 72px);
    background-color: #525659;
    position: relative;
    .fileNotFound {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
    }
  }
}
.header_prez {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  background-color: rgb(15, 25, 65);
  position: relative;
  z-index: 4;
  padding: 0 32px;
  .header_prez_left {
    display: flex;
    align-items: center;
    .backIcon {
      transform: rotate(180deg);
    }

    ul {
      display: inline-block;
      list-style-type: none;
      padding: 0;
      margin: 0;
      line-height: 0;
      vertical-align: middle;
      li {
        display: inline-block;
        button {
          cursor: pointer;
          background-color: transparent;
          border: 0;
          margin-right: 26px;
        }
      }
    }
    h2 {
      color: #fff;
      font-size: 18px;
      span {
        display: inline-block;
        margin-left: 16px;
        font-size: 12px;
      }
    }
  }
  .header_prez_right {
    ul {
      display: inline-block;
      list-style-type: none;
      padding: 0;
      margin: 0;
      line-height: 0;
      vertical-align: middle;
      li {
        &:nth-child(1) {
          button {
            margin-left: 56px;
          }
        }
        display: inline-block;
        button {
          cursor: pointer;
          background-color: transparent;
          border: 0;
        }
      }
    }
    .chevron {
      .right {
        button {
          margin-left: 21px;
          &:disabled {
            opacity: 0.5;
          }
        }
      }
      .left {
        button {
          margin-left: 0;
          &:disabled {
            opacity: 0.5;
          }
          img {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
