.loadingForm {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  z-index: 2;
}
.preview_agence_inner {
  width: 90vw;
  height: 90vh;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 1px #000;
  overflow: hidden;
  position: relative;
  .preview_agence_error {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  iframe {
    width: 100%;
    height: 100%;
  }
  .close-corner {
    width: 60px;
    height: 60px;
    /* border-bottom-left-radius: 80px; */
    background-color: #2175d9;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    border-radius: 50%;
    line-height: 60px;
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
    &:after,
    &:before {
      content: ' ';
      position: absolute;
      top: 29%;
      left: 45%;
      height: 30px;
      width: 6px;
      border-radius: 3px;
      background-color: #f7f5f0;
    }
    img {
      margin: auto;
    }
  }
}
