.pdf__wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  & > div:first-child {
    width: 100%;
    height: 100%;
  }
  .canvas_wrapper {
    width: 100%;
    height: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, -50%);
    & > div:first-child {
      width: 100%;
      height: 100%;
      position: absolute;
      overflow-y: auto;
      overflow-x: auto;
      touch-action: pan-y;
    }
  }
  .pdf_pageNumber {
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    font-size: 10px;
  }

  .bright {
    &:disabled {
      display: none;
    }
  }
  .bleft {
    &:disabled {
      display: none;
    }
  }
}
.pdf__zoom {
  position: absolute;
  top: 30%;
  right: 20px;
  transform: translateY(-30%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #2175d9;
  z-index: 2;
  flex-direction: column;
  // display: none;
  button {
    width: 30px;
    height: 30px;
    &:first-child {
      margin-bottom: 10px;
    }
  }
}
.pdf_navigation {
  position: relative;
  width: 100vw;
  height: auto;
  //display: none;
  .right {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background-color: #2175d9;
    color: white;
    width: 50px;
    height: 50px;
    align-items: center;
    border: 0;
    display: flex;
    justify-content: center;
    cursor: pointer;
    z-index: 2;
    &:disabled {
      display: none;
    }
    img {
      margin-right: 8px;
    }
  }
  .left {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%) rotate(180deg);
    background-color: #2175d9;
    color: white;
    width: 50px;
    height: 50px;
    align-items: center;
    border: 0;
    display: flex;
    justify-content: center;
    cursor: pointer;
    z-index: 2;

    &:disabled {
      display: none;
    }
    img {
      margin-right: 8px;
    }
  }
}
.loader_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
