.preview {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 5;
  .content {
    height: 100%;
    width: 100%;
    background-color: #525659;
  }
  .close-corner {
    width: 80px;
    height: 80px;
    border-bottom-left-radius: 80px;
    background-color: #ffb511;
    position: absolute;
    top: 0;
    right: 0;
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
    &:after,
    &:before {
      content: ' ';
      position: absolute;
      top: 17px;
      left: 45.71429px;
      height: 30px;
      width: 6px;
      border-radius: 3px;
      background-color: #f7f5f0;
    }
    img {
      margin: auto;
    }
  }
}
