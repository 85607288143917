.slideNotFound {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
  width: 100%;
  color: #fff;
  text-align: center;
}
.iframe__warpper {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: calc(100vh - 72px);
  // padding-top: 56.25%;
  .iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}
