.header_prez {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  background-color: rgb(15, 25, 65);
  position: relative;
  z-index: 4;
  padding: 0 32px;
  .header_prez_left {
    display: flex;
    align-items: center;
    .backIcon {
      transform: rotate(180deg);
    }

    ul {
      display: inline-block;
      list-style-type: none;
      padding: 0;
      margin: 0;
      line-height: 0;
      vertical-align: middle;
      li {
        display: inline-block;
        button {
          cursor: pointer;
          background-color: transparent;
          border: 0;
          margin-right: 26px;
        }
      }
    }
    h2 {
      color: #fff;
      font-size: 18px;
    }
  }
  .header_prez_right {
    ul {
      display: inline-block;
      list-style-type: none;
      padding: 0;
      margin: 0;
      line-height: 0;
      vertical-align: middle;
      li {
        &:nth-child(1) {
          button {
            margin-left: 56px;
          }
        }
        display: inline-block;
        button {
          cursor: pointer;
          background-color: transparent;
          border: 0;
        }
      }
    }
    .chevron {
      .right {
        button {
          margin-left: 21px;
          &:disabled {
            opacity: 0.5;
          }
        }
      }
      .left {
        button {
          margin-left: 0;
          &:disabled {
            opacity: 0.5;
          }
          img {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
.prez__detail {
  position: relative;
  background-color: #101943;
  height: 100vh;
  width: 100vw;
  .pdf_prez {
    width: 100vw;
    height: calc(100vh - 72px);
  }
  .video_prez {
    width: 100vw;
    height: calc(100vh - 72px);
  }
  .mode__list {
    width: 100%;
    height: 78vh;
    margin-bottom: 2vh;
    position: relative;
    top: 20.74vh;
    padding: 0 1.66vw;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 2.96vh 2vw;
    grid-auto-rows: max-content;
    overflow-y: auto;
    .mode__list__current__item {
      .mode__list__image {
        border: 2px solid #fff;
      }
      .mode__list__item_title {
        color: #fff !important;
      }
    }
    .mode__list__item_media {
      .mode__list__item_title {
        text-align: center !important;
      }
    }
    .mode__list__item {
      position: relative;
      min-height: 150px;
      // height: 350px;
      .label-active {
        position: absolute;
        right: 0.83vw;
        top: 1.48vh;
        background-color: #2175d9;
        color: #fff;
        padding: 0px 5px;
        font-size: 13px;
        border-radius: 19px;
      }
      .mode__list__image {
        width: 100%;
        height: 23vh;
        border-radius: 5px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center top;
      }
      .mode__list__image__default {
        width: 100%;
        height: 23vh;
        background-color: #f1f1f1;
        color: #000;
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
        font-size: 12px;
        border-radius: 5px;
      }
      .mode__list__icon {
        width: 100%;
        min-height: 180px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
      }
      .mode__list__item_title {
        font-size: 20px;
        color: #2175d9;
        text-align: left;
      }
      .mode__list__item_link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
  }
  .button__left {
    list-style-type: none;
    list-style-type: none;
    position: absolute;
    top: 2.96vh;
    left: 1.66vw;
    z-index: 2;
    display: flex;
    .back {
      display: inline-block;
      margin-right: 5px;
      a {
        width: 56px;
        height: 56px;
        // background-color: #2175d9;
        border: 0;
        display: block;
        text-align: center;
        line-height: 64px;
        cursor: pointer;
        img {
          display: inline-block;
        }
      }
      button {
        // width: 56px;
        // height: 56px;
        background-color: transparent;
        border: 0;
        cursor: pointer;
        text-align: left;
        img {
          display: inline-block;
        }
      }
    }
  }
  .current__prez {
    position: absolute;
    top: 11.85vh;
    left: 1.66vw;
    z-index: 2;
    display: flex;
    color: #fff;

    // width: 41vw;
    // height: 56px;
    // display: inline-block;
    // background-color: #fff;
    // font-size: 18px;
    // color: #2175d9;
    // line-height: 56px;
    // padding-left: 15px;
  }

  .rightButton {
    list-style-type: none;
    list-style-type: none;
    position: absolute;
    top: 2.96vh;
    right: 1.66vw;
    z-index: 2;
    background-color: #fff;
    li {
      display: inline-block;
      width: 56px;
      height: 56px;
      position: relative;
      &:nth-child(1) button::after {
        content: url(../../Assets/detail/meny-rightbar.svg);
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
      button {
        width: 56px;
        height: 100%;
        background-color: transparent;
        border: 0;
        position: relative;
        cursor: pointer;
        &:disabled {
          opacity: 0.5;
        }
      }
    }
  }
  // Pres fleche
  .prev {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    left: 1.66vw;
    transform: translateY(-50%);
    z-index: 2;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    &:disabled img {
      display: none;
    }
    img {
      transform: rotate(180deg);
    }
  }
  .next {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    right: 1.66vw;
    transform: translateY(-50%);
    z-index: 2;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    &:disabled img {
      display: none;
    }
  }
}
