.dd-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 3;
  .dd-header-open {
    border: solid 2px #d7d7d7 !important;
  }
  .dd-header {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: #101943;
    border: solid 2px #d7d7d7;
    font-size: inherit;
    .dd-header-title {
      width: 100%;
      height: 100%;
      padding: 16px 16px;
      position: relative;
      color: #fff;
      display: flex;
      align-items: center;
      span {
        text-align: left;
        letter-spacing: normal;
        font-size: 1em !important;
      }
      &:after {
        content: url(./assets/arrowDown.svg);
        position: absolute;
        top: 50%;
        right: 11px;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
      }
    }
  }
  .dd-list {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 3px #b7b7b7;
    max-height: 240px;
    overflow-y: auto;
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #c7dcf5;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: #c7dcf5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #000080;
    }
    .dd-list-item {
      position: relative;
      padding: 16px;
      height: 100%;
      border: transparent;
      width: 100%;
      font-size: 1em;
      color: #2175d9;
      text-align: left;
      background-color: transparent;
      &:hover {
        background-color: rgba(204, 211, 225, 0.4);
      }
      &:after {
        content: '';
        position: absolute;
        width: calc(100% - 32px);
        height: 1px;
        border-bottom: 1px solid #d7d7d7;
        bottom: 0;
        right: 16px;
      }
    }
    .dd-list-item-active {
      background-color: rgba(199, 209, 220, 0.7) !important;
      color: #2175d9 !important;
      &:before {
        content: url(./assets/check.svg);
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
      }
      &:hover {
        background-color: rgba(199, 209, 220, 0.7);
      }
    }
  }
}
