.wrapper_agence {
  width: 100vw;
  height: 100vh;
  position: relative;
  .loadingForm {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    z-index: 2;
  }
}
