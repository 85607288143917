.error_wrapper {
  width: 100vw;
  height: 100vh;
  background-color: #f7f5f0;
  display: flex;
  align-items: center;
  justify-content: center;
  .offline {
    text-align: center;
    span {
      margin-bottom: 20px;
      font-size: 21px;
      display: block;
    }
  }
}
