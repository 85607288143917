.Tooltip-Wrapper {
  display: inline-block;
  position: relative;
  .Tooltip-Tip {
    position: absolute;
    border-radius: 5px;
    left: 50%;
    transform: translateX(-50%);
    padding: 6px;
    color: black;
    background: white;
    font-size: 14px;
    font-family: sans-serif;
    line-height: 1;
    z-index: 1000;
    white-space: nowrap;
    margin-top: 10px;
    &::before {
      content: ' ';
      left: 50%;
      border: solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-width: 6px;
      margin-left: calc(6px * -1);
    }
    &.bottom {
      bottom: calc(50px * -1);
      &::before {
        bottom: 100%;
        border-bottom-color: #fff;
      }
    }
    &.top {
      top: calc(30px * -1);
      &::before {
        top: 100%;
        border-top-color: #fff;
      }
    }
    &.right {
      left: calc(100% + 30px);
      top: 50%;
      transform: translateX(0) translateY(-50%);
      &::before {
        left: calc(6px * -1);
        top: 50%;
        transform: translateX(0) translateY(-50%);
        border-right-color: #fff;
      }
    }
    &.left {
      left: auto;
      right: calc(100% + 30px);
      top: 50%;
      transform: translateX(0) translateY(-50%);
      &::before {
        left: auto;
        right: calc(6px * -2);
        top: 50%;
        transform: translateX(0) translateY(-50%);
        border-left-color: #fff;
      }
    }
  }
}
