.sync {
  background-color: #f7f5f0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  text-align: center;
  top: 0;
  z-index: 5;
  left: 0;
  .button__left {
    list-style-type: none;
    list-style-type: none;
    position: absolute;
    top: 2.96vh;
    left: 1.66vw;
    z-index: 2;
    display: flex;
    .back {
      display: inline-block;
      margin-right: 5px;
      button {
        width: 56px;
        height: 56px;
        background-color: #2175d9;
        border: 0;
        cursor: pointer;
        img {
          display: inline-block;
        }
      }
    }
  }

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 28.64vw;
    p {
      margin-top: 10px;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: center;
      color: #0f1941;
    }
  }
}
.alert__sync {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 25px;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 8px;
  color: #0f1941;
  display: flex;
  flex-flow: row wrap;
  height: auto;
  padding: 12px 20px 18px;
  visibility: visible;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 450px;
  transform: translate(-50%, -50%);
  z-index: 4;

  .alert__message {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 25px;
    margin-bottom: 0;
    text-align: left;
  }
  .alert__cta {
    margin: 10px 0 0 auto;
    padding-left: 10px;
    text-align: right;
    width: 100%;
    .alert__cta__link {
      -webkit-transition: color 0.2s;
      transition: color 0.2s;
      margin-left: 10px;
      background: transparent;
      border: 0;
      color: #878ca0;
      font-size: 17px;
      cursor: pointer;
      &:nth-child(2),
      &:hover {
        color: #2175d9;
      }
    }
  }
}
