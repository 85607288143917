.input_wrap {
  margin-bottom: 17px;
  textarea {
    resize: vertical;
  }
  label {
    font-size: 12px;
    display: block;
    color: #8a8a8a;
    margin-bottom: 5px;
  }
  .error_msg {
    color: #ab0404;
    font-size: 14px;
  }
  .input_file_wrap {
    width: 100%;
    height: 100px;
    background-color: transparent;
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    background-color: #fff;
    border: 1px solid #d7d7d7;
    border-radius: 5px;
    .input_file_label {
      color: #717171;
      font-weight: 400;
      font-size: 18px;
      & > span {
        font-weight: normal;
        color: #2175d9;
      }
    }
    & > input {
      position: absolute;
      width: 100%;
      height: 100%;
      cursor: pointer;
      opacity: 0;
    }
  }
}
.button_wrap {
  text-align: right;
  margin-bottom: 15px;
  button {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.previewImage_wrap {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #d7d7d7;
  background-color: #fff;
  .previewImage {
    width: 30%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .previewImageDetail {
    padding-left: 60px;
    button {
      color: #2175d9;
      background: transparent;
      border: transparent;
      font-size: 17px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
    }
  }
}
.agence {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .content {
    width: 66%;
    padding: 0;
    background-color: #f7f5f0;
    padding: 0px 31px;
    .title {
      color: #0f1941;
      font-size: 40px;
      margin-bottom: 13px;
    }
    .form {
      height: 85vh;
      overflow-y: auto;
      padding-right: 31px;
      margin-bottom: 30px;
      position: relative;
      z-index: 1;
      input,
      textarea {
        font-size: 16px;
      }
    }
  }
  .sidebar {
    width: 34%;
    background-color: rgb(15, 25, 65);
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.imagePreview {
  margin: 15px 0;
  img {
    max-width: 100%;
    height: auto;
  }
}

.menu__nav {
  list-style-type: none;
  list-style-type: none;
  position: relative;
  z-index: 2;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  .back {
    display: inline-block;
    margin-right: 5px;
    button {
      width: auto;
      height: auto;
      text-align: left;
      background-color: transparent;
      border: 0;
      cursor: pointer;
      img {
        display: inline-block;
        width: 24px;
        height: 24px;
      }
    }
  }
}
.alert__confirmExit {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 25px;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 8px;
  color: #0f1941;
  display: flex;
  flex-flow: row wrap;
  height: auto;
  padding: 12px 20px 18px;
  visibility: visible;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 450px;
  transform: translate(-50%, -50%);
  z-index: 4;

  .alert__message {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 25px;
    margin-bottom: 0;
    text-align: left;
  }
  .alert__cta {
    margin: 10px 0 0 auto;
    padding-left: 10px;
    text-align: right;
    width: 100%;
    .alert__cta__link {
      font-size: 17px;
      -webkit-transition: color 0.2s;
      transition: color 0.2s;
      margin-left: 10px;
      background: transparent;
      border: 0;
      color: #878ca0;
      cursor: pointer;
      &:nth-child(2),
      &:hover {
        color: #2175d9;
      }
    }
  }
}
