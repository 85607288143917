.prez-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 71vw;
  position: relative;
  z-index: 2;
  .item {
    width: 16.66vw;
    height: 19.44vh;
    position: relative;
    padding: 16px;
    border-radius: 10px;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.02);
    background-color: #ffffff;
    margin-bottom: 3vh;
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8) {
      margin-bottom: 0;
    }
    .link {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
    }
    .icon {
      width: 4.16vw;
      height: 4.16vw;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      img {
        // margin-right: -4px;
        // margin-top: -1px;
        width: 57.5%;
        height: 57.5%;
      }
    }
    span {
      position: absolute;
      bottom: 3vh;
      font-size: 1.125rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: #0f1941;
      text-transform: lowercase;
    }
    &:nth-child(1) .icon {
      background-color: #2175d9;
    }
    &:nth-child(2) .icon {
      background-color: rgb(205, 0, 0);
    }
    &:nth-child(3) .icon {
      background-color: #6ba5b4;
    }
    &:nth-child(4) .icon {
      background-color: #fb8c17;
    }
    &:nth-child(5) .icon {
      background-color: #0f1941;
    }
    &:nth-child(6) .icon {
      background-color: #2175d9;
    }
    &:nth-child(7) .icon {
      background-color: rgb(205, 0, 0);
    }
    &:nth-child(8) .icon {
      background-color: #6ba5b4;
    }
  }
  .item-empty {
    background-color: transparent;
    border: 1px solid rgb(247, 245, 240);
  }
}
