.alert__compte {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 25px;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 8px;
  color: #0f1941;
  display: flex;
  flex-flow: row wrap;
  height: auto;
  padding: 12px 20px 18px;
  visibility: visible;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 450px;
  transform: translate(-50%, -50%);
  z-index: 4;

  .alert__message {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 25px;
    margin-bottom: 0;
  }
  .alert__cta {
    margin: 10px 0 0 auto;
    padding-left: 10px;
    text-align: right;
    width: 100%;
    .alert__cta__link {
      -webkit-transition: color 0.2s;
      transition: color 0.2s;
      margin-left: 10px;
      background: transparent;
      border: 0;
      color: #878ca0;
      font-size: 17px;
      cursor: pointer;
      &:nth-child(1),
      &:hover {
        color: #2175d9;
      }
    }
  }
}
.homepage {
  height: 100%;
  width: 100%;
  background-color: #101943;
}
.block-prez {
  // height: 61.85vh;
  // height: 63.85vh;
  padding: 2.96vh;
  position: relative;
}
.illustration-container {
  /* width: 29vw; */
  width: 23.33vw;
  height: calc(100% + -2.96vh);
  // height: 56.11vh;
  // height: 45.11vh;
  background: url(../../Assets/home/puzzle-big-illustration.svg);
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 2.96vh;
  right: 0;
  background-size: contain;
  z-index: 1;
}
.block-documents {
  height: 24.81vh;
  padding: 2.96vh;
  background-color: #f7f5f0;
  display: flex;
  // justify-content: space-between;
}
.cxp-select {
  width: calc(71vw + 2.96vh);
  text-align: right;
  // margin-bottom: 2.96vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .dropdown-wrapper {
    width: 35vw;
    height: 7vh;
    // height: 9vh;
  }
}
@media screen and (max-width: 900px) {
  .illustration-container {
    display: none;
  }
  .block-prez {
    display: flex;
    justify-content: center;
  }
  .block-documents {
    flex-direction: column;
  }
}
