.doc__share {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 25px;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 8px;
  color: #0f1941;
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 20px;
  visibility: visible;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 450px;
  transform: translate(-50%, -50%);
  z-index: 4;
  .errorSend {
    background-color: rgb(255, 210, 210);
    border-radius: 30px;
    color: rgb(150, 0, 0);
    padding: 10px;
    padding-left: 20px;
  }
  .succesSend {
    background-color: rgb(208, 255, 208);
    border-radius: 30px;
    color: rgb(11, 134, 0);
    padding: 10px;
    padding-left: 20px;
  }

  .doc__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: flex-start;
    img {
      cursor: pointer;
    }
  }
  .doc__top_icon {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 1vh;
    .doc_icon_close {
      height: 15px;
    }
    .doc_txt_back {
      padding-left: 10px;
      font-size: 12px;
      color: #2175d9;
    }
  }
  .doc__title__name {
    font-size: 25px;
    // margin-bottom: 20px;
    display: block;
  }
  .share__block__btn {
    margin-top: 20px;
    max-height: 90vh;
    overflow-y: auto;
  }

  button {
    margin-top: 10px;
    max-width: 100% !important;
  }
  .inputWithIcon {
    // margin-top: 3vh;
    flex-flow: row wrap;

    .inputSearch {
      min-height: 54px;
    }
    .inputMessage {
      min-height: 54px;
      white-space: pre-wrap;
    }
  }
  .title-input {
    font-size: 13px;
    color: #aaa;
  }
  .erreur {
    font-size: 13px;
    color: rgb(211, 0, 0);
  }
  .name-file {
    font-size: 13px;
    color: #2175d9;
  }
  li {
    display: flex;
    flex-direction: row;
    text-align: center;
    cursor: pointer;
    padding: 10px 0;
    padding-left: 0.5vw;
    align-items: center;
    border-top: 1px solid rgb(215, 215, 215);

    .doc__icon_zone {
      margin-right: 1.3vw;
      width: 40px;
      height: 40px;
      background-color: #2175d9;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-content: center;
      .doc__icon {
        fill: white;
        width: 16px;
      }
    }
    .doc__name {
      text-align: left;
    }
  }
  .block_emails {
    display: flex;
    margin-top: 7px;
    flex-wrap: wrap;
    overflow: auto;
    max-height: 15vh;
  }
  .icon-item-delete {
    background-color: white;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    margin: 0 0 0 8px;
    padding: 7px;
    background-color: rgba(33, 117, 217, 0.3);
    cursor: pointer;
  }
  .item-text {
    color: #2175d9;
  }
  .item-delete {
    display: flex;
    justify-content: space-between;
    padding: 0px 0px 0px 10px;
    background-color: #e8f1fb;
    border-radius: 30px;
    margin: 0 6px 0 0;
  }

  .item-delete-without-close {
    display: flex;
    justify-content: space-between;
    padding: 2px 10px 2px 10px;
    background-color: #e8f1fb;
    border-radius: 30px;
    margin: 0 8px 0 0;
  }
  .item {
    margin-bottom: 8px;
  }
}
